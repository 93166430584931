@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply box-border;
  }

  html {
    @apply bg-slate-200 box-border font-poppins;
  }

  input[type="text"] {
    @apply bg-white rounded-md py-1 px-2 focus:outline-none focus:ring focus:ring-slate-500 w-2/3 md:w-1/3 text-sm;
  }

  button {
    @apply py-2 px-4 rounded-md text-white font-medium text-sm mt-2 mx-2 w-1/2 md:w-auto;
  }

  .flag-icon {
    @apply w-8 h-8 rounded-full bg-white cursor-pointer;
  }
}

.App {
  text-align: center;
}
